import moment from 'moment';

export default (time) => {
  const cloneTime = moment.duration(time);

  const seconds = cloneTime.seconds();
  const minutes = cloneTime.minutes();
  const hours = cloneTime.hours();
  const days = cloneTime.days();

  return { days, hours, minutes, seconds };
};
