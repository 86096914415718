import lodashReplaces from './lodashReplaces';
import {
  momentDate,
  isMoment,
  formatDate,
  getCurrentDate,
  getCurrentYear,
  getDateObjFromStr,
} from '../../utils/date';
import {
  dateLessThanOtherDate,
  dateLessThanOtherDateByDays,
  momentAddHoursMinutes,
} from './formatDate';

import {
  FIELDS_TYPES,
  LOCALE,
  VAT_ERROR_STATUS,
  VAT_ERROR_STATUS_TEXT,
  MEAL_FIELDS,
  MEAL_LIST_TYPE,
} from '../constants/hotels';
import {
  TIME_SECONDS,
  DATE_TIME_LTS,
  DAYPATTERN,
  MONTHPATTERN,
  FULLPATTERN,
  DEFAULTTIME,
} from '../../constants/time';
import { SERVICETYPE } from '../constants/serviceType';

const simpleFieldsStructure = [
  { field: 'Thumbnail', label: 'Thumbnail', type: FIELDS_TYPES.IMG },
  { field: 'Name', label: 'Имя отеля', type: FIELDS_TYPES.INPUT },
  { field: 'NameEn', label: 'Имя отеля', type: FIELDS_TYPES.INPUT, locale: LOCALE.EN },
  { field: 'Email', label: 'Email', type: FIELDS_TYPES.INPUT },
  { field: 'Phone', label: 'Телефон', type: FIELDS_TYPES.INPUT },
  { field: 'Address', label: 'Адрес', type: FIELDS_TYPES.INPUT },
  { field: 'AddressEn', label: 'Адрес', type: FIELDS_TYPES.INPUT, locale: LOCALE.EN },
  { field: 'RatingTotalVerbose', label: 'Рейтинг в словах', type: FIELDS_TYPES.INPUT },
  { field: 'Rating', label: 'Рейтинг в цифрах', type: FIELDS_TYPES.INPUT },
  { field: 'Stars', label: 'Звезд', type: FIELDS_TYPES.INPUT },
  { field: 'DistanceFromCenter', label: 'Растояние от центра', type: FIELDS_TYPES.INPUT },
  { field: 'PolicyDescription', label: 'PolicyDescription', type: FIELDS_TYPES.INPUT },
  { field: 'CheckinTime', label: 'Время заезда', type: FIELDS_TYPES.CLOCK },
  { field: 'CheckoutTime', label: 'Время выезда', type: FIELDS_TYPES.CLOCK },
  { field: 'Iata', label: 'Iata', type: FIELDS_TYPES.INPUT },
  { field: 'Latitude', label: 'Широта', type: FIELDS_TYPES.INPUT },
  { field: 'Longitude', label: 'Долгота', type: FIELDS_TYPES.INPUT },
  { field: 'RegionName', label: 'Регион', type: FIELDS_TYPES.AUTOCOMPLETE },
  { field: 'City', label: 'Город', type: FIELDS_TYPES.INPUT },
  { field: 'CityEn', label: 'Город', type: FIELDS_TYPES.INPUT, locale: LOCALE.EN },
  { field: 'RegionId', label: 'RegionId', type: FIELDS_TYPES.INPUT },
  { field: 'Type', label: 'Тип', type: FIELDS_TYPES.SELECT },
  { field: 'Country', label: 'Страна', type: FIELDS_TYPES.SELECT },
  { field: 'CountryCode', label: 'Код Страны', type: FIELDS_TYPES.INPUT },
  { field: 'ClassificatorId', label: 'ClassificatorId', type: FIELDS_TYPES.INPUT },
  { field: 'ShortDescription', label: 'Краткое описание', type: FIELDS_TYPES.TEXT_AREA },
  { field: 'ShortDescriptionEn', label: 'Краткое описание', type: FIELDS_TYPES.TEXT_AREA, locale: LOCALE.EN },
];

const mathcingFieldsStructure = [
  { field: 'IdOstrovok', label: 'IdOstrovok', type: 'input' },
  { field: 'IdBronevik', label: 'IdBronevik', type: 'input' },
  { field: 'IdBooking', label: 'IdBooking', type: 'input' },
  { field: 'IdAanda', label: 'IdAanda', type: 'input' },
  { field: 'IdAcadem', label: 'IdAcadem', type: 'input' },
  { field: 'IdHotelbook', label: 'IdHotelbook', type: 'input' },
  { field: 'IdExpedia', label: 'IdExpedia', type: 'input' },
  { field: 'IdTripcom', label: 'IdTripcom', type: 'input' },
  { field: 'IdGiata', label: 'IdGiata', type: 'input' },
];

const requisitesFieldsStructure = [
  { field: 'LegalName', label: 'Юридическое название', type: 'input' },
  { field: 'Inn', label: 'ИНН', type: 'input' },
  { field: 'Kpp', label: 'КПП', type: 'input' },
  { field: 'Ogrn', label: 'ОГРН', type: 'input' },
  { field: 'CorrespondentAcc', label: 'Корп.счет', type: 'input' },
  { field: 'Acc', label: 'Расчетный счет', type: 'input' },
  { field: 'Bik', label: 'БИК', type: 'input' },
  { field: 'BankName', label: 'Название банка', type: 'input' },
];

const additionsFieldsStructure = [
  { field: 'TypeCohortAanda', label: 'TypeCohortAanda', type: FIELDS_TYPES.SELECT },
  { field: 'PriceGuaranteeAanda', label: 'Статус Гарантии цены у AA', type: FIELDS_TYPES.CHECKBOX },
  { field: 'IsSmartHotel', label: 'Умный отель', type: FIELDS_TYPES.CHECKBOX },
];

const restrictionsFieldsStructure = [
  { field: 'AandaGuaranteeDisabled', label: 'Отключить от Гарантии цены', type: FIELDS_TYPES.CHECKBOX },
];

const structureTypes = {
  simple: simpleFieldsStructure,
  mathcing: mathcingFieldsStructure,
  requisites: requisitesFieldsStructure,
  additions: additionsFieldsStructure,
  restrictions: restrictionsFieldsStructure,
};

const defaultRate = {
  Id: 0,
  RateName: '',
  GroupTypeId: 0,
  ImageUrl: '',
  Description: '',
  RefundTimeLimit: '',
  ChargeValue: '',
  ChargeValueUnit: 1,
  ChargeType: 1,
};

const defaultRateValidation = {
  RateName: '',
  GroupTypeId: '',
  ImageUrl: '',
  Description: '',
  RefundTimeLimit: '',
  ChargeValue: '',
};

const getNormalimzeTime = (time) => {
  if (isMoment(time)) {
    return time;
  }
  const [h, m, s] = time.split(':');
  return momentDate({ h, m, s });
};

const normalizeTime = value => formatDate(getNormalimzeTime(value), TIME_SECONDS);

const prepareSimpelFields = (details, type) => structureTypes[type].reduce((acc, el) =>
  ({ ...acc, [el.field]: { ...el, value: details[el.field] } }), {});

const prepareTypesCohortAanda = types => types.map(({ key, value }) => ({ name: value, id: key }));

const getLabelTypesCohortAanda = (types, value) => {
  if (value && types.length) {
    const obj = types.find(({ id }) => id === value);

    return obj && obj.name ? obj.name : value;
  }

  return value;
};

const prepareEcLcConditions = (conditions) => {
  if (!conditions.length) {
    return null;
  }

  return conditions.reduce((acc, el) => ({
    ...acc, [el.Type]: el,
  }), {});
};

const normalizeSimpelFields = (simpleFields) => {
  const keys = Object.keys(simpleFields);
  return keys.reduce((acc, key) => {
    const { value, type } = simpleFields[key];
    const normalizeValue = type === 'clock' ? normalizeTime(value, TIME_SECONDS) : value;
    return { ...acc, [key]: normalizeValue };
  }, {});
};

const prepareAmenities = amenities => amenities.reduce((acc, { Name, Values }, index) => {
  const updatedValues = Values.reduce((vAcc, el, i) => {
    const key = `${i}+${el}value`;
    return { ...vAcc, [key]: { Name: el, key } };
  }, {});

  const key = `${index}+${Name}list`;
  return { ...acc, [key]: { Name, Values: updatedValues, key } };
}, {});

const addItemToList = (list, item) => {
  const array = [...list];

  array.push(item);

  return array;
};

const addRate = list => addItemToList(list, defaultRate);

const addRatesValidation = list => addItemToList(list, defaultRateValidation);

const createRatesValidation = list => list.map(() => ({ ...defaultRateValidation }));

const deleteItemInList = (list, index) => {
  const arr = [...list];

  arr.splice(index, 1);

  return arr;
};

const normalizeAmenities = (amenities, locale) => {
  const keys = Object.keys(amenities);
  return keys.map((key) => {
    const amenitie = amenities[key];
    const valuesKeys = Object.keys(amenitie.Values);

    const normalizeValues = valuesKeys.map(valueKey => amenitie.Values[valueKey].Name);

    return { Name: amenitie.Name, Values: normalizeValues, Locale: locale };
  });
};

const normalizeEclcConditions = (conditions) => {
  if (!conditions) {
    return [];
  }

  const keys = Object.keys(conditions);
  return keys.map((key) => {
    const condition = conditions[key];

    return {
      Type: condition.Type,
      TimeBorder: condition.TimeBorder,
      ChargeValue: Number(condition.ChargeValue),
      ChargeType: condition.ChargeType,
      ChargeTypeUnit: condition.ChargeTypeUnit,
    };
  });
};

const prepareImages = images => images.map((img, i) => ({ ...img, key: `${i}-img` }));
const normalizeImages = images => images.map(img => lodashReplaces.omit(img, 'key'));

const prepareDescription = (description) => {
  const node = document.createElement('div');
  node.innerHTML = description.trim();

  [...node.children].forEach((child) => {
    const textNode = document.createTextNode('\n');
    node.insertBefore(textNode, child);
  });

  return node.innerHTML.trim();
};

const preparedRoomTypes = list => list.map(({ Id, Name }) => ({
  id: Id,
  name: Name,
}));

const preparedRatesFromBackend = list => list.map(({
  id,
  rate_name,
  group_type_id,
  image_url,
  description,
  refund_time_limit,
  charge_value,
  charge_value_unit,
  charge_type,
}) => ({
  Id: id,
  RateName: rate_name,
  GroupTypeId: group_type_id,
  ImageUrl: image_url,
  Description: description,
  RefundTimeLimit: refund_time_limit,
  ChargeValue: charge_value,
  ChargeValueUnit: charge_value_unit,
  ChargeType: charge_type,
}));

const preparedRatesToBackend = list => list.map(({
  Id,
  RateName,
  GroupTypeId,
  ImageUrl,
  Description,
  RefundTimeLimit,
  ChargeValue,
  ChargeValueUnit,
  ChargeType,
}) => ({
  id: Id,
  rate_name: RateName,
  group_type_id: GroupTypeId,
  image_url: ImageUrl,
  description: Description,
  refund_time_limit: RefundTimeLimit,
  charge_value: ChargeValue,
  charge_value_unit: ChargeValueUnit,
  charge_type: ChargeType,
}));

const normalizeDescription = description => description.replace(/\n/g, '');

const prepareRMAmenities = arr => arr.reduce((acc, el, i) => {
  const key = `${el}-${i}`;
  return { ...acc, [key]: { value: el, key } };
}, {});

const normalizeRMAmenities = (obj) => {
  const keys = Object.keys(obj);
  return keys.map(k => obj[k].value);
};

const prepareRoomGroups = roomGroups => roomGroups.reduce((acc, item, i) => (
  { ...acc,
    [`${i}-rooom`]: {
      ...item,
      Amenities: prepareRMAmenities(item.Amenities),
      Images: prepareImages(item.Images),
      key: `${i}-rooom`,
    } }), {});

const normalizeRoomGroups = (roomGroups) => {
  const keys = Object.keys(roomGroups);
  return keys.map((key) => {
    const item = roomGroups[key];
    return {
      Name: item.Name,
      Description: item.Description,
      Images: normalizeImages(item.Images),
      Amenities: normalizeRMAmenities(item.Amenities),
    };
  });
};

const getUpdatedDate = () => {
  const currentDate = formatDate(getCurrentDate(), DATE_TIME_LTS);
  return currentDate;
};

const makeDefaultForm = (matchHotels) => {
  const defaultFormData = {
    Static: {
      Address: '',
      AddressEn: '',
      CheckinTime: '00:00:00',
      CheckoutTime: '00:00:00',
      City: '',
      CityEn: '',
      Country: '',
      CountryCode: '',
      Deleted: false,
      Description: '',
      DescriptionEn: '',
      DistanceFromCenter: '',
      Email: '',
      Iata: '',
      Id: 0,
      Images: [],
      Latitude: 0,
      Longitude: 0,
      Name: '',
      NameEn: '',
      Phone: '',
      PolicyDescription: '',
      RatingTotalVerbose: '',
      RegionId: '',
      RegionName: '',
      RoomGroups: [],
      ShortDescription: '',
      ShortDescriptionEn: '',
      Source: 'backoffice',
      Stars: '',
      Thumbnail: '',
      Type: '',
      UpdateDate: '',
      Amenities: [],
      AmenitiesEn: [],
      Additions: {
        TypeCohortAanda: 'undefined',
        PriceGuaranteeAanda: false,
        IsSmartHotel: false,
      },
      Restrictions: {
        AandaGuaranteeDisabled: false,
      },
      Requisites: {
        LegalName: '',
        Inn: '',
        Kpp: '',
        Ogrn: '',
        CorrespondentAcc: '',
        Acc: '',
        Bik: '',
        BankName: '',
      },
    },
    Matching: {
      IdAanda: matchHotels ? matchHotels.IdAanda.value : '',
      IdAcadem: matchHotels ? matchHotels.IdAcadem.value : '',
      IdBooking: '',
      IdBronevik: matchHotels ? matchHotels.IdBronevik.value : '',
      IdExpedia: matchHotels ? matchHotels.IdExpedia.value : '',
      IdTripcom: matchHotels ? matchHotels.IdTripcom.value : '',
      IdGiata: matchHotels ? matchHotels.IdGiata.value : '',
      IdHotelbook: '',
      IdOstrovok: matchHotels ? matchHotels.IdOstrovok.value : '',
      IdSmartway: 0,
    },
  };

  return {
    simpleFields: prepareSimpelFields(defaultFormData.Static, 'simple'),
    matching: prepareSimpelFields(defaultFormData.Matching, 'mathcing'),
    Requisites: prepareSimpelFields(defaultFormData.Static.Requisites, 'requisites'),
    additions: prepareSimpelFields(defaultFormData.Static.Additions, 'additions'),
    restrictions: prepareSimpelFields(defaultFormData.Static.Restrictions, 'restrictions'),
    Amenities: prepareAmenities(defaultFormData.Static.Amenities),
    AmenitiesEn: prepareAmenities(defaultFormData.Static.AmenitiesEn),
    Images: prepareImages(defaultFormData.Static.Images),
    Description: prepareDescription(defaultFormData.Static.Description),
    DescriptionEn: prepareDescription(defaultFormData.Static.DescriptionEn),
    RoomGroups: prepareRoomGroups(defaultFormData.Static.RoomGroups),
    Deleted: defaultFormData.Static.Deleted, // ?
    Id: defaultFormData.Static.Id,
    UpdateDate: defaultFormData.Static.UpdateDate,
    Source: defaultFormData.Static.Source,
    IdSmartway: defaultFormData.Matching.IdSmartway,
  };
};

const getDefaultValidation = () => ({
  EmployeeFirst: '',
  ReservationNumber: '',
  ReservationDate: '',
  GuestCount: '',
  CheckinDate: '',
  CheckoutDate: '',
  Hotel: {
    Name: '',
    Address: '',
    City: '',
    Location: '',
  },
  Base: '',
  commission: '',
  CompanyId: '',
  ProjectId: '',
  DepartmentId: '',
  HasVat: '',
  Total: '',
  From: '',
  Penalty: {
    Base: '',
  },
  Additional: '',
  BreakfastName: '',
  CurrencyInfo: {
    CurrencyMFInCurrency: '',
    CurrencyInclusive: '',
    CurrencyBase: '',
    CurrencyCommission: '',
  },
});

const fullDateAfterOtherDate = (value) => {
  const { CheckinDate, CheckoutDate, Hotel: { CheckinTime, CheckoutTime } } = value;

  const checkinHoursAndMinutes = CheckinTime.split(':');
  const checkoutHoursAndMinutes = CheckoutTime.split(':');
  const fullCheckInDate = momentAddHoursMinutes(CheckinDate, checkinHoursAndMinutes[0], checkinHoursAndMinutes[1]);
  const fullCheckOutDate = momentAddHoursMinutes(CheckoutDate, checkoutHoursAndMinutes[0], checkoutHoursAndMinutes[1]);

  return dateLessThanOtherDate(fullCheckOutDate, fullCheckInDate) >= 0;
};

const dateAfterOtherDate = (value) => {
  const { CheckinDate, CheckoutDate, CustomCheckInDate, CustomCheckOutDate } = value;

  if (!CustomCheckInDate && !CustomCheckOutDate) {
    return dateLessThanOtherDateByDays(momentDate(CheckoutDate), momentDate(CheckinDate)) >= 0;
  }

  return fullDateAfterOtherDate(value);
};

const getRegionId = regionId => regionId ? regionId.toString() : null;

const infoDate = ({ CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate, ServiceType }) => {
  const isHotel = ServiceType === SERVICETYPE.HOTEL;

  const currentYear = getCurrentYear();
  const mStart = getDateObjFromStr(CheckinDate);
  const mEnd = getDateObjFromStr(CheckoutDate);

  let startDateFormat =
    mStart.year() === mEnd.year() && mStart.month() === mEnd.month() ?
      DAYPATTERN :
      MONTHPATTERN;
  let endDateFormat = MONTHPATTERN;

  if (mStart.year() !== mEnd.year() && mStart.year() === currentYear) {
    endDateFormat = FULLPATTERN;
  }
  if (mStart.year() !== mEnd.year() && mStart.year() !== currentYear) {
    startDateFormat = FULLPATTERN;
    endDateFormat = FULLPATTERN;
  }

  if (isHotel) {
    if (CustomCheckInDate) {
      startDateFormat = startDateFormat === DAYPATTERN ?
        `${MONTHPATTERN} ${DEFAULTTIME}` :
        `${startDateFormat} ${DEFAULTTIME}`;
    }
    if (CustomCheckOutDate) {
      endDateFormat += ` ${DEFAULTTIME}`;
    }
  }

  const rStart = mStart.format(startDateFormat);
  const rEnd = mEnd.format(endDateFormat);

  if (mStart.clone().startOf('day').isSame(mEnd.clone().startOf('day')) && !isHotel) {
    return rEnd;
  }

  return `${rStart} - ${rEnd}`;
};

const getPrevVersions = (versions, currentVersion) => {
  const sortItems = [...versions].sort((prev, next) => {
    if (prev.Id < next.Id) return -1;
    if (prev.Id > next.Id) return 1;

    return 0;
  });

  const idx = sortItems.findIndex(el => el.Id === currentVersion.Id);

  return sortItems.slice(0, idx);
};


const getSumVatDetails = (list) => {
  if (!list.length) return 0;

  if (list.length === 1) return list[0].Amount;

  return list.reduce((sum, { Amount }) => sum + Amount, 0);
};

const getSumVatAmount = (currentVersion, versions) => {
  const prevVersions = getPrevVersions(versions, currentVersion);
  const prevVersionsVatReady = prevVersions.filter(({ InternalVat: { VatReady } }) => VatReady);

  return prevVersionsVatReady.reduce((sum, { InternalVat: { VatInfo: { VatDetails, HasVat } } }) => {
    if (HasVat) return sum + getSumVatDetails(VatDetails);

    return sum;
  }, 0);
};

const getMaxRate = (currentVersion, versions) => {
  if (versions.length === 1) return '';

  const prevVersions = getPrevVersions(versions, currentVersion);

  if (!prevVersions.length) return '';

  const prevVatDetails = prevVersions.filter(({ InternalVat: { VatReady, VatInfo } }) => VatReady && VatInfo.HasVat)
    .flatMap(({ InternalVat: { VatInfo: { VatDetails } } }) => VatDetails);

  if (!prevVatDetails.length) return '';

  return Math.max(...prevVatDetails.map(item => item.Rate));
};

const getVatErrorText = (status) => {
  switch (status) {
    case VAT_ERROR_STATUS.SUCCESS:
    case VAT_ERROR_STATUS.VERSION_NOT_FOUND:
    case VAT_ERROR_STATUS.OPERATION_NOT_FOUND:
    case VAT_ERROR_STATUS.VERIFICATION_FAILED:
    case VAT_ERROR_STATUS.NO_PREVIOUS_VAT:
    case VAT_ERROR_STATUS.SAVING_FAILED:
    case VAT_ERROR_STATUS.UNHANDLED_CASE:
    case VAT_ERROR_STATUS.ALREADY_EXISTS: {
      return VAT_ERROR_STATUS_TEXT[status];
    }
    default: {
      return VAT_ERROR_STATUS_TEXT.DEFAULT;
    }
  }
};

const filterMeal = (meal = []) => {
  if (!meal.includes(1) || meal.includes(6) || meal.includes(8)) {
    return MEAL_FIELDS.filter(({ value }) => value !== 4);
  }

  if (meal.includes(4)) {
    return MEAL_FIELDS.filter(({ value }) => value !== 6 && value !== 8);
  }

  return MEAL_FIELDS;
};

const prepareMealForSave = (mealList) => {
  const selectedTypeMeal = mealList && mealList.length > 0 ? MEAL_LIST_TYPE.find(({ list }) =>
    list.length === mealList.length && list.every(num => mealList.includes(num))) : MEAL_LIST_TYPE[8];
  const category = selectedTypeMeal.value;

  return {
    Include: category !== 0,
    Name: selectedTypeMeal.label,
    Category: category,
    Additional: false,
    Price: 0,
  };
};

export {
  prepareSimpelFields,
  prepareAmenities,
  prepareImages,
  prepareDescription,
  prepareRoomGroups,
  normalizeImages,
  normalizeAmenities,
  normalizeEclcConditions,
  normalizeSimpelFields,
  normalizeDescription,
  normalizeRoomGroups,
  getNormalimzeTime,
  getUpdatedDate,
  makeDefaultForm,
  addRate,
  addRatesValidation,
  deleteItemInList,
  preparedRatesFromBackend,
  preparedRatesToBackend,
  createRatesValidation,
  preparedRoomTypes,
  prepareEcLcConditions,
  getDefaultValidation,
  prepareTypesCohortAanda,
  getLabelTypesCohortAanda,
  dateAfterOtherDate,
  fullDateAfterOtherDate,
  getRegionId,
  infoDate,
  getSumVatAmount,
  getMaxRate,
  getVatErrorText,
  getSumVatDetails,
  getPrevVersions,
  filterMeal,
  prepareMealForSave,
};
