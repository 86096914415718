import { ERRORSFORALL } from '../constants/tripValidator';

/**
 * Analytics value
 * @typedef {Object} AnalyticsValue
 * @property {string} Name - Название
 * @property {number} Id - ID значения аналитики
 */

/**
 * Analytics
 * @typedef {Object} Analytics
 * @property {string} Name - Название
 * @property {Array.<AnalyticsValue>} Values - Список возможных значений
 * @property {number} Id - ID аналитики
 */

/**
 * @param {Analytics} currentAnalytics - Текущая аналитика
 * @param {Array.<number>} currentValues - Текущие значения аналитик
 * @param {number} newValueId - ID нового значения
 */
const applyAnalytics = (newValueId, currentValues, currentAnalytics) => {
  const { Values: analyticsValues } = currentAnalytics;

  return [
    ...currentValues.filter(valueId => !analyticsValues.some(({ Id }) => Id === valueId)),
    newValueId,
  ];
};

/**
 * @param {Analytics} currentAnalytics - Список всех пользовательских аналитик
 * @param {Array.<number>} currentValues - Текущие значения аналитик
 */
const removeAnalytics = (currentValues = [], currentAnalytics) =>
  currentValues.filter(valueId => !currentAnalytics.Values.some(({ Id }) => valueId === Id));

/**
 * @param {Analytics} singleAnalytics - Пользовательская аналитика
 * @param {Array.<number>} tripAnalytics - Значения аналитик для всей поездки
 * @param {Array.<number>} serviceAnalytics - Значения аналитик для услуги
 */
const validateSingleAnalytics = (analyticsValues = [], singleAnalytics = {}) => {
  const { Values, Required, Id } = singleAnalytics;
  const hasValue = Values.some(({ Id: valueId }) => analyticsValues.includes(valueId));

  return {
    [Id]: Required && !hasValue ? ERRORSFORALL.NOANALYTICS : '',
  };
};

/**
 * @param {Array.<Analytics>} analytics - Список всех пользовательских аналитик
 * @param {Array.<number>} tripAnalytics - Значения аналитик для всей поездки
 * @param {Array.<number>} serviceAnalytics - Значения аналитик для услуги
 */
const validateAnalytics = (tripAnalytics = [], serviceAnalytics = [], analytics = []) =>
  analytics.reduce((acc, singleAnalytics) => {
    const { ApplyToTrip } = singleAnalytics;
    const tripAnalyticsValueIds = ApplyToTrip ? tripAnalytics : serviceAnalytics;

    return {
      ...acc,
      ...validateSingleAnalytics(tripAnalyticsValueIds, singleAnalytics),
    };
  }, {});

export { applyAnalytics, removeAnalytics, validateAnalytics, validateSingleAnalytics };
