import { DOCUMENTS } from '../constants/documentsConstructor';

const combineOperations = (addedOperations, notAddedOperations) => {
  const allOperations = [...addedOperations, ...notAddedOperations];

  const result = allOperations.reduce((acc, { ServiceType, ServiceOperationNames }) => {
    if (!acc[ServiceType]) {
      acc[ServiceType] = { ServiceType, ServiceOperationNames: [] };
    }

    ServiceOperationNames.forEach((Operation) => {
      const operationExists = acc[ServiceType].ServiceOperationNames.some(
        ({ name }) => name === Operation.name);

      if (!operationExists) {
        acc[ServiceType].ServiceOperationNames.push(Operation);
      }
    });

    return acc;
  }, {});

  return Object.values(result);
};

const updateServices = (
  selectedValues,
  notAddedOperations,
  serviceOperations,
  tabId,
) => {
  const updatedServiceOperations = [];
  const updatedNotAddedOperationsMap = {};

  serviceOperations.forEach(({ ServiceType, ServiceOperationNames }) => {
    const selectedOperations = [];
    const unselectedOperations = [];

    ServiceOperationNames.forEach(({ name, nameContents }) => {
      const isSelected = selectedValues.includes(name);
      const operation = { name, added: isSelected };

      if (isSelected) {
        if (tabId === DOCUMENTS.ACTS) {
          operation.nameContents = nameContents;
        }

        selectedOperations.push(operation);
      } else {
        if (tabId === DOCUMENTS.ACTS) {
          operation.nameContents = [];
        }

        unselectedOperations.push(operation);
      }
    });

    if (selectedOperations.length) {
      updatedServiceOperations.push({
        ServiceType,
        ServiceOperationNames: selectedOperations,
      });
    }

    if (unselectedOperations.length) {
      if (!updatedNotAddedOperationsMap[ServiceType]) {
        updatedNotAddedOperationsMap[ServiceType] = {
          ServiceType,
          ServiceOperationNames: [],
        };
      }
      updatedNotAddedOperationsMap[ServiceType].ServiceOperationNames.push(...unselectedOperations);
    }
  });

  notAddedOperations.forEach(({ ServiceType, ServiceOperationNames }) => {
    const remainingOperations = [];

    ServiceOperationNames.forEach(({ name, nameContents }) => {
      const isSelected = selectedValues.includes(name);
      const operation = { name, added: isSelected };

      if (tabId === DOCUMENTS.ACTS) {
        operation.nameContents = nameContents;
      }

      if (isSelected) {
        const existingService = updatedServiceOperations.find(item => item.ServiceType === ServiceType);

        if (existingService) {
          existingService.ServiceOperationNames.push(operation);
        } else {
          updatedServiceOperations.push({
            ServiceType,
            ServiceOperationNames: [operation],
          });
        }
      } else {
        remainingOperations.push(operation);
      }
    });

    if (remainingOperations.length) {
      if (!updatedNotAddedOperationsMap[ServiceType]) {
        updatedNotAddedOperationsMap[ServiceType] = {
          ServiceType,
          ServiceOperationNames: [],
        };
      }
      updatedNotAddedOperationsMap[ServiceType].ServiceOperationNames.push(...remainingOperations);
    }
  });

  const updatedNotAddedOperations = Object.values(updatedNotAddedOperationsMap);

  return { updatedServiceOperations, updatedNotAddedOperations };
};

const updateNameContents = (serviceName, ServiceOperations, droppedItems = []) => (
  ServiceOperations.map((service) => {
    const { ServiceOperationNames } = service;

    const hasOperation = ServiceOperationNames.some(({ name }) => name === serviceName);

    if (!hasOperation) {
      return service;
    }

    const updatedServiceOperationNames = ServiceOperationNames.map(operation =>
        operation.name === serviceName ? { ...operation, nameContents: droppedItems } : operation
      );

    return { ...service, ServiceOperationNames: updatedServiceOperationNames };
  })
);

export {
  combineOperations,
  updateServices,
  updateNameContents,
};
