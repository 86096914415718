const formatTimer = (days, hours, minutes, seconds) => {
  if (days) {
    return `${days} д. ${hours} ч. ${minutes} мин. ${seconds} сек.`;
  }

  if (hours) {
    return `${hours} ч. ${minutes} мин. ${seconds} сек.`;
  }

  return `${minutes} мин. ${seconds} сек.`;
};

export { formatTimer };
