const debounceXhr = (fn, timer) => {
  let timeout = null;

  return (...args) => {
    const promise = new Promise((resolve) => {
      const later = () => {
        timeout = null;
        promise.currentFn = fn.apply(this, args);
        resolve(promise.currentFn);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, timer);
    });

    promise.abort = () => {
      clearTimeout(timeout);
      if (promise.currentFn && promise.currentFn.abort) {
        promise.currentFn.abort();
      }
    };

    return promise;
  };
};

export default debounceXhr;
