const CASES = [2, 0, 1, 1, 1, 2];

const decline = (number, titles) => {
  const ind = (number % 100 > 4 && number % 100 < 20) ?
    2 : CASES[(number % 10 < 5) ? number % 10 : 5];

  return titles[ind];
};

export default decline;
