import { REFUNDTICKET, TARIFFS, VALUETICKET, BAGGAGE } from '../constants/airline';

const CARRYONPLACESDEFAULTVALUE = 'включена';

const prepareBaggage = (value, places) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return places ? `включён ${places}` : 'включён';
    case VALUETICKET.NOTDEFINED:
      return '';
    default:
      return BAGGAGE.NOTOFFERED;
  }
};

const prepareCarryOn = (value, places) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return places || CARRYONPLACESDEFAULTVALUE;
    case VALUETICKET.CHARGE:
      return 'платно';
    default:
      return '';
  }
};

const prepareRefundable = (value) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return TARIFFS.NOTOFFERED;
    case VALUETICKET.INCLUDED:
      return TARIFFS.INCLUDED;
    case VALUETICKET.CHARGE:
      return TARIFFS.CHARGE;
    case VALUETICKET.NOTDEFINED:
      return TARIFFS.SPECIFY;
    default:
      return '';
  }
};

const prepareChangeable = (value) => {
  switch (value) {
    case VALUETICKET.NOTOFFERED:
      return 'Изменения: запрещены';
    case VALUETICKET.INCLUDED:
      return 'Изменения до вылета: без сбора';
    case VALUETICKET.CHARGE:
      return 'Изменения до вылета: со сбором';
    default:
      return '';
  }
};

const prepareRefundableAfterDeparture = (value) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return 'Можно отменить билет после вылета';
    case VALUETICKET.NOTOFFERED:
      return 'Возврат после вылета: запрещен';
    default:
      return '';
  }
};

const prepareChangeableAfterDeparture = (value) => {
  switch (value) {
    case VALUETICKET.INCLUDED:
      return 'Изменения после вылета: без сбора';
    case VALUETICKET.CHARGE:
      return 'Изменения после вылета: со сбором';
    case VALUETICKET.NOTOFFERED:
      return 'Изменения после вылета: запрещены';
    default:
      return '';
  }
};

const refundTicketWithCondition = (details) => {
  Object.keys(refundTicketWithCondition).forEach((field) => {
    if (REFUNDTICKET[refundTicketWithCondition[field]]) {
      details.push(`Возврат ${field}: ${REFUNDTICKET[refundTicketWithCondition[field]]}`);
    }
  });
};

export {
  refundTicketWithCondition,
  prepareRefundable,
  prepareBaggage,
  prepareCarryOn,
  prepareChangeable,
  prepareRefundableAfterDeparture,
  prepareChangeableAfterDeparture,
};
