import { CODE, PASSPORT } from '../constants/employee';
import { getPassportType, getPassportDueDate } from './passport';

export const preparedCardText = (bonuses, bonusCodes, typeBonus) => {
  if (!bonuses.length) {
    return [];
  }
  const filteredBonuses = bonuses.filter(({ Type }) => Type === typeBonus);
  return filteredBonuses.map(({ Id, Number, Code }) => {
    if (!bonusCodes) {
      return { id: Id, value: Number, name: Code };
    }
    const { Name } = bonusCodes.find(element => element.Code === Code) || Code;
    return { id: Id, value: Number, name: Name };
  });
};

export const preparedDocumentText = ({ Surname, Name, Number, Patronymic, Type, DueDate }, code) => {
  const items = [
    { text: 'Фамилия:', value: Surname, key: `${Number}-3` },
    { text: 'Имя:', value: Name, key: `${Number}-1` },
    {
      text: 'Отчество:',
      value: Type !== PASSPORT.FOREIGNPASSPORT ? Patronymic : null,
      key: `${Number}-2`,
    },
    { text: 'Номер:', value: Number, key: `${Number}-4` },
    {
      text: 'Срок действия:',
      value: getPassportDueDate(DueDate),
      key: `${Number}-5`,
    },
  ];

  return {
    items,
    label: getPassportType(code, Type),
  };
};

export const getCountryName = (citizenship, code) => {
  if (!citizenship.length || !code) {
    return null;
  }
  const country = citizenship.find(({ Code }) => Code === code);
  const { Name } = country;

  return Name || code;
};

export const getShortName = ({ Surname, Name, Patronymic }) => {
  const isPatronymic = Patronymic.length ? `${Patronymic.charAt(0)}.` : '';

  return `${Surname} ${Name.charAt(0)}.${isPatronymic}`;
};

// сортировка массива фамилий по алфавиту
export const sortListSurnameABC = (list = []) => {
  const sortedSurname = (first, second) => (first.toLowerCase() > second.toLowerCase() ? 1 : -1);

  return (list.sort((a, b) => (sortedSurname(a.Surname, b.Surname))));
};

// вычисление высоты блока по длине массива фамилий
export const calculatingBlockHeight = (length = 0) => {
  if (length >= 7) {
    return (Math.ceil(length / 7) * 27);
  }

  return (length * 27);
};

// определение типа документа
export const defineTypeOfDocuments = (documentType, code, findDocument) => {
  if (code === CODE.RU) {
    return findDocument.Type === documentType.DomesticPassport ? PASSPORT.PASSPORTRF : PASSPORT.INTERNATIONPASSPORT;
  }

  return PASSPORT.FOREIGNLABELPASSPORT;
};

// формирование текста на основе документа
export const definePassport = (documentType, employee, documents) => {
  if (!documents) return '';

  const { Code } = employee;

  const currentDocumentId = documents.DefaultDocumentId;
  const findDocument = employee.Documents.find(document => document.Id === currentDocumentId);

  const type = defineTypeOfDocuments(documentType, Code, findDocument);
  const isPatronymic = findDocument.Patronymic ? ` ${findDocument.Patronymic}` : '';

  return `${type} ${findDocument.Surname} ${findDocument.Name}${isPatronymic}, ${findDocument.Number}`;
};
